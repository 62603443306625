import * as React from "react"
import {
  ChakraProvider,
  Box,
  Container,
  theme,
} from "@chakra-ui/react"
import { ProductionResult } from './components/productionResult'
import { ProductionSelector } from "./components/productionSelector"
import { load_all } from "./planner/load_defs"
import { ProductionChain, ProductionParameters, calculateProductionChain } from "./planner/calc"

let registered_popstate = false;

export const App = () => {

  load_all();

  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [productionChain, setProductionChain] = React.useState<ProductionChain | undefined>(undefined);

  const handleBack = () => {
    setHasSubmitted(false);
  };

  const handleSubmit = (targetItem: string, targetRate: number) => {
      const params: ProductionParameters = {
          excluded_buildings: new Set(["burner-assembling-machine", "assembling-machine-1", "assembling-machine-2", "stone-furnace", "steel-furnace"]),
          terminal_inputs: new Set([
              "iron-plate",
              "copper-plate",
              "steel-plate",
              "petroleum-gas",
              "light-oil",
              "heavy-oil",
              "sand",
              "water",
              "stone",
              "se-cryonite-rod",
              "coal"
          ])
      };

  if (!registered_popstate) {
    window.addEventListener("popstate", e => handleBack());

    registered_popstate = true;
  }

    const productionChain = calculateProductionChain(targetItem, targetRate, params);
    setProductionChain(productionChain);
    setHasSubmitted(true);

    window.history.pushState({}, "", "select");
  };

  return (
    <ChakraProvider theme={theme}>
      <Container paddingTop='6em' paddingBottom='6em' maxW='100ch'>
        {
          hasSubmitted 
            ? (<ProductionResult chain={productionChain!} />)
            : (<ProductionSelector onSubmit={handleSubmit} />)
        }
      </Container>
    </ChakraProvider>
  )
}
