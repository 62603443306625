import { Matrix, inverse } from "ml-matrix";
import { Building, getCraftingSpeed } from "./building";
import { Item, shouldRecycle } from "./item";
import { Recipe, findPrimaryRecipe, findRecyclingRecipe } from "./recipe";

export interface ProductionChain {
    inputs: Map<string, number>,
    outputs: Map<string, number>,
    intermediate_products: Map<string, number>,
    buildings: Map<string, number>,
    nodes: GraphNode[],
    minPowerKW: number,
    maxPowerKW: number
}

export interface GraphNode {
    building: string,
    recipe: Recipe,
    quantity: number,
    inputs: Map<string, number>,
    outputs: Map<string, number>
}

export interface ProductionParameters {
    excluded_buildings: Set<string>,
    terminal_inputs: Set<string>
}

export function calculateProductionChain(target_item: string, target_rate: number,
     params: ProductionParameters): ProductionChain {


    // Step 1 - Build production graph. 
    //
    // We'll determine which recipes to use and where each product goes, but we'll defer determining 
    // the exact amounts until step 2.

    let desired_products = [target_item];
    let nodes = new Map<Recipe, GraphNode>();

    // For each product, choose which recipe to use and update the graph.
    while (desired_products.length > 0) {
        const item = desired_products.pop()!;

        // Choose a recipe to produce the item.
        const recipe = chooseRecipeForItem(item, params);

        // Do we already have a graph node for this recipe? If not, create it.
        // Note that recipe will be undefined for raw/basic materials.
        if (recipe && !nodes.has(recipe)) {
            const building = chooseBuildingForRecipe(recipe, params);

            const node: GraphNode = {
                building,
                recipe,

                // The rest of the fields will be populated in step 2.
                quantity: 0,
                inputs: new Map<string, number>(),
                outputs: new Map<string, number>(),
            };
            nodes.set(recipe, node);

            // Enqueue and track ingredients.
            for (const item of recipe.inputs.keys()) {
                desired_products.push(item);
            }
        } 
    }

    // Repeatedly try to recycle byproducts until we can't recycle any more.
    let consumed_items = new Set<string>();
    let produced_items = new Set<string>();

    for (const node of nodes.values()) {
        node.recipe.inputs.forEach((_, i) => consumed_items.add(i));
        node.recipe.outputs.forEach((_, i) => produced_items.add(i));
    }

    // Create a copy of the raw input items prior to adding recycling chains. We need this to determine
    // item constraints - if the output of a recycling chain feeds back into the input of the entire
    // production chain, we don't want to create a rate constraint.
    const raw_inputs = new Set([...consumed_items].filter(i => !produced_items.has(i)));

    while (true) {

        const recycleable_byproducts = [...produced_items].filter(i => shouldRecycle(i) && !consumed_items.has(i));
        const recycle_recipes = recycleable_byproducts.map(findRecyclingRecipe).filter(r => r).map(r => r!);

        if (recycle_recipes.length > 0) {
            // Recycle this item.
            const recipe = recycle_recipes[0];

            const node: GraphNode = {
                building: recipe.buildings[0], // TODO How do we want to select a building?
                recipe,

                // The rest of the fields will be populated in step 2.
                quantity: 0,
                inputs: new Map<string, number>(),
                outputs: new Map<string, number>(),
            };
            nodes.set(recipe, node);

            // Update produced and consumed items.
            node.recipe.inputs.forEach((_, i) => consumed_items.add(i));
            node.recipe.outputs.forEach((_, i) => produced_items.add(i));

        } else {
            // No more byproducts to recycle.
            break;
        }
    }


    // Step 2 - Solve for production rate and building count at each node.
    //
    // We'll write a linear equation for the production and consumption rate of each target item and
    // intermediate product as a function of the production of each recipe. We'll set the intermediate
    // product net rate to zero (produce exactly as  much as needed), and we'll set the target item 
    // rate equal to the desired output. Finally, we'll solve the system of equations.
    //
    // Solve AX = B, where X is the quantity of each recipe to make (per second), A is the quantity
    // of each item producted by the given recipe, and B is the target output rate of each product.
    // X = A^-1 B
    //
    // Example:
    // Target is 10 Electronic Circuits / s
    // Graph is Copper Plate -> Copper Cable -> | Electronic Circuit
    //          Stone Tablet -> - - - - - - - - |
    //
    // Let a be the circuit recipe rate and b be the copper cable recipe rate.
    // 
    // We'll write an equation for each product in terms of the recipe rates.
    //  2a + 0b = 10        2 circuits/s * (circuit recipe count) = 10 circuits/s
    // -6a + 4b = 0         each circuit recipe consumes 6 cable per second, each cable production 
    //                      produces 4 per second. We want net zero.
    //
    // As a matrix equation:
    // [ 2 0] * [a] = [10]
    // [-6 4]   [b]   [0 ]
    //
    // X = A^-1 B
    // a = 5, b = 15

    // Find constrained items. A constrained item is one where we have have a target net production
    // rate. In general, constrained items are those which are neither a process input or process
    // byproduct. For now, we find constrained items as items which are both an input and an output.
    // We constrain them to a net rate of zero, meaning it is produced and consumed at the same rate.
    // The target item is also constrained to be produced at the target rate.
    //
    // Note that the number of constrained items should match the number of recipes. If that is not
    // the case, we may need to add a way to relax the constraints in some way, such as allowing
    // additional net inputs or outputs to un-constrain an intermediate product.

    let recipes = [...nodes.keys()];
    const constrained_items = [...[...produced_items].filter(i => consumed_items.has(i)), target_item]
        .filter(i => !raw_inputs.has(i));
    if (constrained_items.length != nodes.size) {
        throw new Error(`Number of constrained items (${constrained_items.length}) does not match the number of recipes (${nodes.size}).`);
    }

    // Create an inverse map of constrained item to index. This will allow us to efficiently find
    // the index of each item, as well as quickly determine if an item is constrained.
    const item_index_map = new Map<string, number>(
        [...constrained_items.entries()].map(([n,i]) => [i,n])
    );


    // A matrix.
    // Has dimensions of (constraint count) x (constraint count).
    // Each row represents a constrained item and each column represents a constrained recipe.
    let constraint_matrix = Matrix.zeros(constrained_items.length, constrained_items.length);

    // For each recipe, populate the corresponding column based on the product and ingredients.
    for (let col_index = 0; col_index < recipes.length; col_index++) {
        const recipe = recipes[col_index];
        const col = new Array(constrained_items.length).fill(0);

        const crafting_rate = getCraftingSpeed(nodes.get(recipe)!.building) / recipe.crafting_time;
        
        // Recipe outputs.
        for (const [item, quantity] of recipe.outputs) {
            if (item_index_map.has(item)) {
                col[item_index_map.get(item)!] = quantity * crafting_rate;
            }
        }

        // Inptus. Note that the negative sign implies these are being consumed instead of
        // produced.
        for (const [item, quantity] of recipe.inputs) {
            if (item_index_map.has(item)) {
                col[item_index_map.get(item)!] = -quantity * crafting_rate;
            }
        }

        constraint_matrix.setColumn(col_index, col);
    }


    // B vector.
    let target_quantities = Matrix.zeros(constrained_items.length, 1);
    target_quantities.set(item_index_map.get(target_item)!, 0, target_rate);

    // Solve for amount of each recipe.
    let inverse_recipe_matrix = inverse(constraint_matrix);
    let recipe_quantities = inverse_recipe_matrix.mmul(target_quantities);

    // TODO Handle singular matrix?

    // Update the graph nodes with recipe quantities. Compute inputs and outputs for each node
    // and aggregate.
    let total_production = new Map<string, number>();
    let total_consumption = new Map<string, number>();

    for (let i = 0; i < recipes.length; i++) {
        const recipe = recipes[i];
        const node = nodes.get(recipe)!;
        node.quantity = recipe_quantities.get(i, 0);

        // Calculate the number of recipes that we finish per second.
        const net_crafting_rate = node.quantity * getCraftingSpeed(node.building) / 
                                        node.recipe.crafting_time;

        // Calculate input and output rates.
        node.inputs = new Map(Array.from(
            node.recipe.inputs, 
            ([item, quantity]) => [item, quantity * net_crafting_rate]
        ));

        for (const [item, quantity] of node.recipe.outputs) {
            node.outputs.set(item, quantity * net_crafting_rate);
        }

        // Update aggregate production and consumption.
        node.inputs.forEach((rate, item) => {
            total_consumption.set(item, (total_consumption.get(item) ?? 0) + rate);
        });
        node.outputs.forEach((rate, item) => {
            total_production.set(item, (total_production.get(item) ?? 0) + rate);
        });
    }



    // Step 3 - aggregate totals and build the production chain object.

    // Determine the set of input items and byproducts. Input items are anything that is consumed but
    // not produced. Byproducts are items that are producted but not consumed, and are not equal to the
    // goal item.

    const input_items = new Map([...total_consumption].filter(
        ([i]) => !total_production.has(i)
    ));
    const output_items = new Map([...total_production].filter(
        ([i]) => !total_consumption.has(i)
    ));
    const intermediate_products = new Map([...total_production].filter(
        ([i]) => total_consumption.has(i)
    ));

    // Count the number of each recipe and building.
    const building_counts = new Map<string, number>();
    const recipe_counts = new Map<[Recipe, string], number>();

    nodes.forEach(node =>{
        building_counts.set(node.building, 
            (building_counts.get(node.building) ?? 0) + Math.ceil(node.quantity));

        recipe_counts.set([node.recipe, node.building],
            (recipe_counts.get([node.recipe, node.building]) ?? 0) + node.quantity);
    });

    // Compute the minimum and maximum power consumption.
    const [minPowerKW, maxPowerKW] = [0,0]; /* [...building_counts].reduce(
        ([minSum, maxSum], [building,count]) => 
            [minSum + getMinPowerKW(building) * count, maxSum + getMaxPowerKW(building) * count], 
        [0, 0]
    );*/

    

    return {
        outputs: output_items,
        buildings: building_counts,
        inputs: input_items,
        intermediate_products: intermediate_products,
        nodes: [...nodes.values()],
        maxPowerKW,
        minPowerKW
    };
}

function chooseBuildingForRecipe(recipe: Recipe, params: ProductionParameters): string {
    return recipe.buildings.filter(b => !params.excluded_buildings.has(b))[0];
}

function chooseRecipeForItem(item: string, params: ProductionParameters): Recipe | undefined {
    // If this is a terminal input, don't try to produce it.
    if (params.terminal_inputs.has(item)) {
        return undefined;
    }

    // For now, this just finds the first recipe with the item as a primary output, but is broken 
    // out here so that we can add logic to select between multiple recipes in the future.
    return findPrimaryRecipe(item);
}