import { loadBuildings } from "./building";
import { loadItems } from "./item";
import { loadRecipes } from "./recipe";

const definition_url = "https://raw.githubusercontent.com/factoriolab/factoriolab/main/src/data/sxp/data.json";

let loaded = false;

export function load_all() {
    if (loaded) { return; }

    const req = new XMLHttpRequest();
    req.open('get', definition_url, false);
    // req.responseType = 'json';
    req.onload = function() {
        if (req.status == 200) {
            let data = JSON.parse(req.response);

            // TODO
            data.defaults.excludedRecipes.push("se-formatting-1");
            data.defaults.excludedRecipes.push("se-formatting-2");
            data.defaults.excludedRecipes.push("se-formatting-3");
            data.defaults.excludedRecipes.push("se-formatting-4");
            data.defaults.excludedRecipes.push("se-scrap-recycling");
            data.defaults.excludedRecipes.push("se-distortion-drive");
            data.defaults.excludedRecipes.push("se-space-water-decontamination");
            data.defaults.excludedRecipes.push("se-scrap-decontamination");
            data.defaults.excludedRecipes.push("se-bio-sludge-decontamination");

            loadItems(data.items, data.icons);
            loadBuildings(data.items);
            loadRecipes(data.recipes, data.defaults.excludedRecipes);
            
            loaded = true;
        } else {
            throw new Error("Fetch failed.");
        }
    };
    req.send();
}