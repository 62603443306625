import * as React from 'react';
import {
    Box,
    Center,
    Heading,
    Image,
    Stack,
    Text
} from "@chakra-ui/react";
import { ITEMS, getItemIconUrl } from '../planner/item';

export interface ItemTileProps {
    item: string,
    detail: string
}

export const ItemTile = ({ item, detail }: ItemTileProps) => {
    const itemInfo = ITEMS.get(item)!;

    return (
        <Box minW='120px' display='inline-block' borderWidth='1px' borderRadius='lg' padding='10px' m='5px'>
            <Center>
                <Image src={getItemIconUrl(item)} w='66px' />
            </Center>
            <Center>
                <Heading size='xs' mt='5px' textTransform='capitalize'>{itemInfo.name}</Heading>
            </Center>
            <Center>
                <Text color='gray.500' fontSize='xs'>{detail}</Text>
            </Center>
        </Box>
    );
};