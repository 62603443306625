import { Building } from "./building";
import { Item, shouldRecycle } from "./item";

export interface Recipe {
    id: string,
    name: string,
    outputs: Map<string, number>,
    inputs: Map<string, number>,
    buildings: string[],
    crafting_time: number,
    is_recycle: boolean,
}

export let RECIPES: Map<string, Recipe> = new Map();

let primaryRecipeByItem: Map<string, Recipe> | undefined = undefined;
let primaryRecyclingRecipeByItem: Map<string, Recipe> | undefined = undefined;

export function loadRecipes(definitions: any, excluded_recipes: string[]) {
    const excluded_set = new Set<string>(excluded_recipes);

    RECIPES = new Map(definitions.map((def: any) => {
        const exclude = excluded_set.has(def.id);
        const parsed = parseRecipe(def, exclude);
        return [parsed.id, parsed];
    }));
}

function parseRecipe(def: any, exclude: boolean): Recipe {
    return {
        id: def.id,
        name: def.name,
        outputs: new Map(Object.entries(def.out)),
        inputs: new Map(Object.entries(def.in)),
        buildings: def.producers,
        crafting_time: def.time,
        is_recycle: exclude // TODO
    };
}

export function getPrimaryRecipeOutput(recipe: Recipe): [string, number] {
    return recipe.outputs.entries().next().value;
}

export function findPrimaryRecipe(item: string): Recipe | undefined {
    if (primaryRecipeByItem === undefined) {
        primaryRecipeByItem = createRecipeMap([...RECIPES.values()]);
    }

    return primaryRecipeByItem.get(item);
}

export function findRecyclingRecipe(item: string): Recipe | undefined {
    if (primaryRecyclingRecipeByItem === undefined) {
        primaryRecyclingRecipeByItem = createRecyclingMap([...RECIPES.values()]);
    }

    return primaryRecyclingRecipeByItem.get(item);
}

// Create a map showing the primary production recipe for each item.
function createRecipeMap(recipes: Recipe[]): Map<string, Recipe> {
    let map = new Map();

    recipes.filter(r => !(r.is_recycle ?? false)).forEach(r => {
        const primary_output = r.outputs.keys().next().value;
        if (!map.has(primary_output)) {
            map.set(primary_output, r);
        }
    });

    return map;
}

// Create a map showing the primary recyling recipe for each recyclable item.
function createRecyclingMap(recipes: Recipe[]): Map<string, Recipe> {
    return new Map(
        recipes
            .filter(r => r.is_recycle)
            .filter(r => !r.id.includes("-2") && !r.id.includes("-3") && !r.id.includes("-4")) // TODO
            .map(r => {

            // Find primary recyling input.
            const recycle_input = [...r.inputs.keys()].filter(shouldRecycle)[0];

            return [recycle_input, r];

        })
    );
}
