export interface Item {
    id: string,
    name: string,
    is_tech: boolean
}

export let ITEMS: Map<string, Item> = new Map();
export let ITEM_ICONS: Map<string, string> = new Map();

export function loadItems(definitions: any, icon_definitions: any) {
    ITEMS = new Map(definitions.map((def: any) => {
        const parsed = parseItem(def);
        return [parsed.id, parsed];
    }));

    ITEM_ICONS = new Map(icon_definitions.map((def: any) => {
        const pos_str: string = def.position;
        const offset = pos_str.split(" ")
            .map(s => Number(s.substring(0, s.length - 2)) / -66);

        return [def.id, `icon-${offset[0]}-${offset[1]}.png`];
    }));
}

function parseItem(item_data: any): Item {
    return {
        id: item_data.id,
        name: item_data.name,
        is_tech: 'technology' in item_data
    };
}

export function getItemIconUrl(item_id: string): string {
    return ITEM_ICONS.get(item_id)!;
}

export function getItemName(item_id: string): string {
    return ITEMS.get(item_id)!.name;
}

// Should we attempt to recycle this item?
export function shouldRecycle(item: string): boolean {
    // TODO
    switch (item) {
        case "se-broken-data":
        case "se-contaminated-bio-sludge":
        case "se-contaminated-space-water":
        case "se-contaminated-scrap":
        case "se-junk-data":
            return true;

        default: 
            return false;
    }
}