import * as React from "react";
import { 
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Card,
    CardBody,
    CardHeader,
    Center,
    Divider,
    Flex,
    Heading, 
    Image,
    List,
    ListIcon,
    ListItem,
    Stack, 
    StackDivider,
    Stat,
    StatGroup,
    StatHelpText,
    StatLabel,
    StatNumber,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import { ProductionParameters, calculateProductionChain } from "../planner/calc";
import { BUILDINGS, getBuildingName } from "../planner/building";
import { getItemIconUrl, getItemName } from "../planner/item";
import { ProductionChain } from "../planner/calc";
import { ItemTile } from "./itemTile";
import { getPrimaryRecipeOutput } from "../planner/recipe";
import { ArrowForwardIcon } from "@chakra-ui/icons";

interface ProductionResultProps {
    chain: ProductionChain
}

export const ProductionResult = ({chain}: ProductionResultProps) => {
    return (
        <Card>
            <CardHeader>
                <Heading size='lg'>Production Results</Heading>
            </CardHeader>
            <CardBody>
                <Stack divider={<StackDivider />} spacing='8'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>Outputs</Heading>
                        {[...chain.outputs.entries()].map( (
                            [id,qty]) => (
                                <ItemTile key={id} item={id} detail={`${qty.toFixed(2)} / s`} />
                            )
                        )}
                    </Box>

                    <Box>
                        <Heading size='xs' textTransform='uppercase'>Inputs</Heading>
                        {[...chain.inputs.entries()].map( (
                            [id,qty]) => (
                                <ItemTile key={id} item={id} detail={`${qty.toFixed(2)} / s`} />
                            )
                        )}
                    </Box>

                    <Box>
                        <Heading size='xs' textTransform='uppercase'>Buildings</Heading>
                        {[...chain.buildings.entries()].map( (
                            [id,qty]) => (
                                <ItemTile key={id} item={id} detail={`${qty.toFixed(0)}`} />
                            )
                        )}
                    </Box>
                    
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>Intermediate Products</Heading>
                        {[...chain.intermediate_products.entries()].map( (
                            [id,qty]) => (
                                <ItemTile key={id} item={id} detail={`${qty.toFixed(2)} / s`} />
                            )
                        )}
                    </Box>

                    <Box>
                        <Heading size='xs' textTransform='uppercase'>Recipes</Heading>
                        <Accordion allowMultiple mt='1em'>
                        {
                            chain.nodes.map(n => (
                                <AccordionItem key={n.recipe.id}>
                                    <h2>
                                        <AccordionButton>
                                            <Image h='1em' src={getItemIconUrl(getPrimaryRecipeOutput(n.recipe)[0])} mr='0.5em' />
                                            <Box as='span' flex='1' textAlign='left'>
                                                <Text textTransform='capitalize' display='inline'>{n.recipe.name}</Text> <Text display='inline' color='gray.500'>x{Math.ceil(n.quantity)}</Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel>
                                        <Flex alignItems='stretch' flexWrap='wrap'>
                                            <ItemTile item={n.building} detail={`${Math.ceil(n.quantity)}`} />

                                            <Box>
                                                <Divider orientation='vertical' h='140px' ml='1em' mr='1em' colorScheme='gray' />
                                            </Box>

                                            {[...n.inputs.entries()].map( (
                                                [id,qty]) => (
                                                    <ItemTile key={id} item={id} detail={`${qty.toFixed(2)} / s`} />
                                                )
                                            )}

                                            <Center>
                                                <ArrowForwardIcon color='gray.500' />   
                                            </Center>
                                            
                                            {[...n.outputs.entries()].map( (
                                                [id,qty]) => (
                                                    <ItemTile key={id} item={id} detail={`${qty.toFixed(2)} / s`} />
                                                )
                                            )}
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))
                        }
                        </Accordion>
                    </Box>
                </Stack>
            </CardBody>
        </Card>
    );
}