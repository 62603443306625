export enum Building {
    BiochemicalFacility,
    DecontaminationFacility,
    RecyclingFacility,
    SpaceAssembler,
    SpaceManufactory,
    SuperComputer,
    ThermalRadiator
}

export enum PowerType {
    Electric,
    Burner,
    None,
}

export interface BuildingInfo {
    id: string,
    name: string,
    craftingSpeed: number,
    powerType: PowerType,
    maxConsumption: number,
    minConsumption: number,
    moduleSlots: number,
}

export let BUILDINGS: Map<string, BuildingInfo> = new Map();

export function loadBuildings(item_defs: any) {
    BUILDINGS = new Map(item_defs.filter((def: any) => 'machine' in def).map((def: any) => {
        const parsed = parseBuilding(def);
        return [parsed.id, parsed];
    }));
}

function parseBuilding(def: any): BuildingInfo {
    return {
        id: def.id,
        name: def.name,
        craftingSpeed: def.machine.speed,
        powerType: parsePowerType(def.machine.type ?? "none"),
        maxConsumption: def.machine.usage,
        minConsumption: def.machine.drain ?? 0,
        moduleSlots: def.machine.modules ?? 0
    };
}

function parsePowerType(type: string): PowerType {
    switch (type) {
        case "electric": return PowerType.Electric;
        case "burner": return PowerType.Burner;
        case "none": return PowerType.None;
        default: throw new Error(`Unknown power type ${type}.`);
    }
}

export function getBuildingInfo(building: string): BuildingInfo {
    return BUILDINGS.get(building)!;
}

export function getBuildingName(building_id: string): string {
    return BUILDINGS.get(building_id)!.name;
}

export function getCraftingSpeed(building: string): number {
    return getBuildingInfo(building).craftingSpeed;
}

