import * as React from 'react';
import {
    Avatar,
    Box,
    Button,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    NumberInput,
    NumberInputField,
    Text
} from "@chakra-ui/react";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList
} from "@choc-ui/chakra-autocomplete";
import { ITEMS, Item, getItemIconUrl } from '../planner/item';

export const ProductionSelector = ({ onSubmit = (i: string, r: number) => {} }) => {
    const [items, setItems] = React.useState<Item[]>([...ITEMS.values()]
                .filter(i => !i.is_tech)
                .slice(0, 20)
                );
    const [targetItem, setTargetItem] = React.useState('');
    const [targetRate, setTargetRate] = React.useState(1);
    const [isInvalid, setIsInvalid] = React.useState(true);

    const updateQueryText = (val: string) => {
        // Filter the selection list down to the top N values. If we display all of the possibilities,
        // it is very slow.
        setItems(
            [...ITEMS.values()]
                .filter(i => !i.is_tech)
                .filter(i => i.name.toLowerCase().includes(val.toLowerCase()))
                .slice(0, 20)
        )
    };

    const handleSubmit = () => {
        onSubmit(targetItem, targetRate);
    };


    
    return (
        <Flex pt="48" justify="center" align="center" w="full">
            <FormControl>
                <Box mb='1em'>
                    <FormLabel>Target Item</FormLabel>
                    <AutoComplete openOnFocus onChange={v => { setTargetItem(v); setIsInvalid(false); }}>
                        <AutoCompleteInput variant='filled' onChange={e => updateQueryText(e.target.value)} />
                        <AutoCompleteList>
                            {items.map(i => (
                                <AutoCompleteItem key={i.id} value={i.id} label={i.name} textTransform='capitalize'>
                                    <Avatar size='sm' name={i.name} src={getItemIconUrl(i.id)} />
                                    <Text ml='4' textTransform='capitalize'>{i.name}</Text>
                                </AutoCompleteItem> 
                            ))}
                        </AutoCompleteList>
                    </AutoComplete>
                    <FormHelperText>Which item do you want to produce?</FormHelperText>
                </Box>

                <Box mb='1em'>
                    <FormLabel>Production Rate (Units/s)</FormLabel>
                    <NumberInput onChange={v => setTargetRate(parseInt(v))} defaultValue={1}>
                        <NumberInputField />
                    </NumberInput>
                    <FormHelperText>How many of this item should we produce each second?</FormHelperText>
                </Box>

                <Box mt='2em'>
                    <Button w='100%' isDisabled={isInvalid} onClick={handleSubmit}>Calculate</Button>
                </Box>
            </FormControl>
        </Flex>
    );
}